import ApiService from "@/common/api.service";
import {FETCH_SURVEY, CREATE_RESPONSE} from "./actions.type";
import {SET_SURVEY, FINISH_SURVEY, START_LOADING, FINISH_LOADING, SET_ERROR} from "@/store/mutations.type";
import {getUrlParam} from "../helpers/fns";

const state = {
  survey: {},
  questions: [],
  questionsCount: 0,
  currentThankyouScreen: null,
  isAvailable: true,
  hasPassword: false,
  isLoading: false,
  isFinished: false,
  error: {},
}

const getters = {
  survey(state) {
    return state.survey;
  },
  questions(state) {
    return state.questions;
  },
  questionsCount(state) {
    return state.questionsCount;
  },
  currentThankyouScreen(state) {
    return state.currentThankyouScreen;
  },
  isLoading(state) {
    return state.isLoading;
  },
  isFinished(state) {
    return state.isFinished;
  },
  error(state) {
    return state.error;
  },
}

const actions = {
  [FETCH_SURVEY]({commit}, payload) {
    commit(START_LOADING);
    const {survey_id, password} = payload;
    const query = password ? `?password=${password}` : '';
    return new Promise((resolve, reject) => {
      ApiService.get(`${process.env.VUE_APP_API_URL}/survey/${survey_id}${query}`)
        .then(response => {
          let errorCode = response.code ? response.code.toString() : null;
          if (errorCode && !errorCode.startsWith('2')) {
            commit(SET_ERROR, response);
            throw response;
          }
          commit(SET_SURVEY, response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit(FINISH_LOADING);
        });
    });
  },
  [CREATE_RESPONSE]({commit}, {survey_id, responses, isFinished, comment, urlParamsStr, calculatedFields, thankyouScreenId}) {
    // Check for preview mode
    const isPreviewMode = getUrlParam('preview_mode') === 'true'

    if (isPreviewMode) {
      commit(FINISH_SURVEY, thankyouScreenId)
      return Promise.resolve()
    }

    commit(START_LOADING);
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/survey/${survey_id}`, {
        responses,
        isFinished,
        comment,
        urlParamsStr,
        calculatedFields
      })
        .then(response => {
          let errorCode = response.code ? response.code.toString() : null;
          if (errorCode && !errorCode.startsWith('2')) {
            throw response;
          }
          commit(FINISH_SURVEY, thankyouScreenId);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit(FINISH_LOADING);
        });
    });
  },
}

const mutations = {
  [START_LOADING](state) {
    state.isLoading = true;
  },
  [FINISH_LOADING](state) {
    state.isLoading = false;
  },
  [SET_SURVEY](state, survey) {
    state.survey = survey;
    state.questions = survey.questions.rows;
    state.questionsCount = survey.questions.show_count;
    state.error = '';
  },
  [FINISH_SURVEY](state, thankyouScreenId) {
    state.currentThankyouScreen = state.survey.thankyouScreens.find(screen => screen._id === thankyouScreenId) || state.survey.thankyouScreens[0];
    state.isFinished = true;
  },
  [SET_ERROR](state, error) {
    state.error = error;
  },
}

export default {
  state,
  actions,
  mutations,
  getters
};
