<template>
  <VCard>
    <template #title>
      <div class="space-y-2">
        <div>
          <ik-image
              v-if="type === 'responded' && thankyouScreen && thankyouScreen.image.type === 'custom' && thankyouScreen.image.src"
              :src="thankyouScreen.image.src | parseImgUrl"
              style="max-width: 50px; height: 50px;"
              class="w-full object-cover select-none pointer-events-none"
              alt="Image"/>
          <a-icon v-else :type="errorTypes[type].icon" style="font-size: 50px" :style="titleColor"/>
        </div>
        <div :style="titleColor">
          {{ errorTypes[type].text }}
        </div>
      </div>
    </template>

    <div :style="textColor">
      {{ errorTypes[type].description }}
    </div>

    <template #button>
      <a
          v-if="showButton"
          :href="button.link"
          target="_parent">
        <a-button
            type="primary"
            size="large"
            class="btn-action-primary"
            :style="[buttonBgColor, buttonTextColor]">
          {{ button.text }}
        </a-button>
      </a>
    </template>
  </VCard>
</template>

<script>
import {mapGetters} from "vuex";
import {parseImgUrl} from "../helpers/fns";
import themeMixin from "../mixins/theme.mixin";
import VCard from "./VCard";

export default {
  name: "VError",
  props: {
    type: String,
    translations: Object,
  },
  components: {VCard},
  mixins: [themeMixin],
  filters: {parseImgUrl},
  computed: {
    ...mapGetters(["survey", "error"]),
    errorTypes() {
      const translations = this.survey?.translations || this.translations;
      return {
        notFound: {text: 'Survey not found', description: 'Maybe it was deleted.', icon: "close-circle"},
        notAvailable: {
          text: translations.surveyMessages?.titleSurveyPaused?.value || translations.surveyMessages?.titleSurveyPaused?.defaultValue,
          description: translations.surveyMessages?.descSurveyPaused?.active ? translations.surveyMessages?.descSurveyPaused?.value || translations.surveyMessages?.descSurveyPaused?.defaultValue : null,
          icon: "pause-circle"
        },
        responsesLimitExceeded: {text: 'Frozen!', description: 'Survey was disabled', icon: "close-circle"},
        responded: {
          text: translations.surveyMessages?.titleSurveyTaken?.value || translations.surveyMessages?.titleSurveyTaken?.defaultValue,
          description: translations.surveyMessages?.descSurveyTaken?.active ? translations.surveyMessages?.descSurveyTaken?.value || translations.surveyMessages?.descSurveyTaken?.defaultValue : null,
          icon: "check-circle"
        },
        other: {text: 'Crasssh', description: 'Internal server error. Please try again later.', icon: "frown"},
      }
    },
    thankyouScreen() {
      return this.survey.thankyouScreens?.find(screen => screen.isActive) || this.error?.data?.thankyouScreen;
    },
    button() {
      return this.thankyouScreen?.button || {};
    },
    showButton() {
      return this.button.isActive && this.button.link && this.button.text;
    }
  }
}
</script>

<style scoped>

</style>
