<template>
  <div>
    <div v-if="title && title.length > maxLength" class="whitespace-pre-line">
      {{ isTextCollapsed ? title : truncateStr(title) }}
      <a-icon v-if="!isTextCollapsed" type="down" class="cursor-pointer px-1" @click="collapseText"/>
      <div v-else class="text-center">
        <a-icon type="up" class="cursor-pointer p-1 pointer-events-auto" @click="collapseText"/>
      </div>
    </div>
    <div v-else class="whitespace-pre-line">{{ title }}</div>
  </div>
</template>

<script>
import {truncate} from "lodash";

export default {
  name: "VCollapsedText",
  props: {
    title: {
      type: String,
      required: true,
    },
    maxLength: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      isTextCollapsed: false,
    }
  },
  methods: {
    truncateStr(value) {
      return truncate(value, {length: this.maxLength});
    },
    collapseText() {
      this.isTextCollapsed = !this.isTextCollapsed;
      this.$emit('collapse', this.isTextCollapsed);
    },
  }
}
</script>

<style scoped>

</style>
